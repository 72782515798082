<template>
    <button class="like like_btn" :class="{'active' : boardData.is_like === true}" :disabled="disabledBtn()" @click="runLikeFunc"><span>{{returnCount(boardData.wr_good, 999)}}</span></button>
</template>

<script>
    // @ is an alias to /src
    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'BoardLikeMentorCommuLayout',
        mixins: [],
        components: {
        },
        inject: ['runLikeFunc'],
        props: {
            boardData: {
                default: {},
            },
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods:{
            returnCount(count, maxCount) {
                if(maxCount < count) {
                    return maxCount+'+';
                }
                return count
            },
            disabledBtn() {
                return this.UserInfo.mb_type === 0;
            },
        },
        watch: {

        },
    }
</script>
